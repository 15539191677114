import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;

export default class ChatService
{
    #api = null;
    constructor() {
        this.#api = `${API_URL}user/chat`;
    }
    paginate(data=null, index=1) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);
    }
    getAllConversations(data={}){
        let url = `${this.#api}/get/all/conversations`;

        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    createNewConversation(data){
        let url = `${this.#api}/create/new-conversation`;
        return apiService.post(url, data);
    }
    getAllConversationParticipationUser(conversationId){
        let url = `${this.#api}/${conversationId}/get/participation/user`
        return apiService.get(url);
    }
    removeParticipationFromConversation(conversationId,userId)
    {
        let url = `${this.#api}/${conversationId}/remove-participation-user/${userId}`
        return apiService.get(url);
    }
    addParticipationUserFromConversation(conversationId,data)
    {
        let url = `${this.#api}/${conversationId}/add-participation-user`
        return apiService.post(url,data);
    }
    markWholeConversationAsRead(conversationId){
        let url = `${this.#api}/${conversationId}/mark-as-read`;
        return apiService.get(url);
    }
    clearConversation(conversationId)
    {
        let url = `${this.#api}/${conversationId}/clear-conversation`;
        return apiService.get(url);
    }
    deleteConversation(conversationId)
    {
        let url = `${this.#api}/${conversationId}/delete-conversation`;
        return apiService.get(url);
    }
}