<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="900" scrollable>
      <v-card>
        <v-toolbar
            dark>
          <v-card-title>

            <span>Contacts</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12">
                <v-list two-line>
                  <div class="card" style="height: 400px;  overflow-y: scroll;">
                    <div class="card-body">
                      <template v-for="(item, index) in participation_users">
                        <v-divider v-if="item.divider"
                                   :key="index"
                                   :inset="item.inset">
                        </v-divider>
                        <v-list-item
                            v-else
                            :key="item.full_name"
                        >
                          <v-list-item-avatar>
                            <v-img v-if="item.image_path && item.image_path.real" :src="item.image_path.real"></v-img>
                            <span v-else class="symbol symbol-35 symbol-light-success" >
                            <span class="symbol-label font-size-sm font-weight-bold cursor-pointer">
                              {{item.first_name ? item.first_name.charAt(0).toUpperCase() : '' }}
                              {{ item.last_name ? item.last_name.charAt(0).toUpperCase() : '' }}</span>
                          </span>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row>
                                <v-col md="10">
                                  <span class="font-weight-bold" v-if="currentUser.id==item.id">You</span>
                                  <span class="font-weight-bold" v-else>{{item.full_name}}</span> <a class="font-weight-medium" v-if="item.email"> ({{item.email}}) </a>
                                </v-col>
                                <v-col md="2" class="text-right" v-if="conversation_admin==currentUser.id && item.id!=currentUser.id">
                                     <span @click="removeParticipationUser(item.id)">
                                  <i class="fas fa-close" style="color: red"></i>
                              </span>
                                </v-col>
                              </v-row>

                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                      </template>
                    </div>
                  </div>
                </v-list>
              </v-col>
              <v-col md="12" v-if="conversation_admin==currentUser.id">
                <v-autocomplete :loading="userLoading" :error="$v.participation_user.user_id.$error" label="Add participant user" append-inner-icon="mdi-magnify"
                                :items="users" outlined dense @change="changeUser()" class="form-control-solid" :search-input.sync="participantUserSearch" v-model="participation_user.user_id" :filter="() => true"  item-text="display_text" item-value="id" >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              class="cancel-btn"
              x-large
              @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn v-if="conversation_admin==currentUser.id"
              color="btn btn-primary"
              dark
              x-large
              :loading="loading"
              @click="save()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import userService from "@/core/services/user/UserService";
import ChatService from "@/core/services/chat/ChatService";
import {required, sameAs, minLength, numeric} from "vuelidate/lib/validators";

const chat=new ChatService();
const user=new userService();
export default {
  props:['conversation_admin'],
  validations: {
    participation_user:{
      user_id: {required},
    },
  },
  data(){
    return{
      loading:false,
      dialog:false,
      title:'',
      conversationId:null,
      users:[],
      participantUserSearch:null,
      userDetail:null,
      userLoading:false,
      participation_users:[],
      participation_user:{
        user_id:null
      }
    }
  },
  methods:{
    openDialog(){
      this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
      this.resetData();
    },
    resetData(){
      this.title=null;
      this.conversationId=null;
      this.participation_users=[];
      this.participantUserSearch=null;
    },
    addParticipant(conversationId) {
      this.title='Add';
      this.openDialog();
      this.conversationId=conversationId;
      this.getAllParticipationUser();
    },
    changeUser(){

    },
    async searchUser(data) {
      this.userLoading=true;
      user
          .searchUserForChat(data)
          .then((response) => {
            this.users = [];
            let users=[];
            users = response.data.users;
            if(users.length > 0){
              users.map((user) => {
                user.display_text = `${user.personal_id.toUpperCase()} - ${user.full_name} - ${user.email}`
                this.users.push(user);
              });
            }else{
              this.users=[];
            }

          })
          .catch(err => {

          })
          .finally(() => (this.userLoading = false))
    },
    getAllParticipationUser(){
      chat
          .getAllConversationParticipationUser(this.conversationId)
          .then(response => {
            this.participation_users=response.data.participation_users;
          })
          .catch((err) => {


          })
          .finally(() => {

          });
    },
    save(){
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        chat
            .addParticipationUserFromConversation(this.conversationId, this.participation_user)
            .then(response => {
              this.$snotify.success("Added successfully");
              this.getAllParticipationUser();
              this.participantUserSearch=null;
            })
            .catch((err) => {


            })
            .finally(() => {

            });
      }
    },
    removeParticipationUser(userId){
      this.$confirm({
        message: `Are you sure you want to delete this item permanently ?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            chat
                .removeParticipationFromConversation(this.conversationId,userId)
                .then(response => {
                  this.$snotify.success("Removed successfully");
                  this.getAllParticipationUser();
                })
                .catch((err) => {


                })
                .finally(() => {

                });
          }
        }
      });
    },


  },
  watch:{
    participantUserSearch(val) {
      let data = {
        info:val,
      };
      this.searchUser(data);
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
}
</script>