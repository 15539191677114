<template>
  <v-app>
    <v-dialog
        v-model="dialog"
        max-width="900"
        scrollable>
      <v-card>
        <v-toolbar
            dark>
          <v-card-title>

            <span>{{ title }} Conversation</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>

              <v-col md="12">
                <v-chip class="ma-2"  close @click:close="removeUser(index)" color="primary"
                        v-show="conversation.users.length > 0" v-for="(selected_user,index) in conversation.users" :key="index"
                        label>

                  {{`${selected_user.personal_id.toUpperCase()} - ${selected_user.full_name} - ${selected_user.email}`  }}
                </v-chip>
                <v-autocomplete clearable :loading="userLoading"  label="Add participant user" prepend-inner-icon="mdi-magnify" :items="users" outlined dense @change="changeUser" class="form-control-solid" :search-input.sync="participantUserSearch" v-model="userDetail" :filter="() => true"  item-text="display_text"  return-object>

                </v-autocomplete>
              </v-col>
              <v-col md="12">
                <v-text-field v-model="conversation.title" label="Conversation title" outlined dense>
                </v-text-field>
              </v-col>
              <v-col md="12">
                <v-textarea v-model="conversation.description" label="Conversation description" outlined dense></v-textarea>
              </v-col>
              <v-col cols="6" md="4">
                <span class="font-weight-bold">Is private ?</span>
                <v-switch
                    v-model="conversation.private"
                    :label="conversation.private?'Yes':'No'"
                    color="primary"

                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              class="cancel-btn"
              x-large
              @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn
              color="btn btn-primary"
              dark
              x-large
              :loading="loading"
              @click="createOrUpdate()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import userService from "@/core/services/user/UserService";
import ChatService from "@/core/services/chat/ChatService";
const chat=new ChatService();
const user=new userService();
export default {
  data(){
    return{
      dialog:false,
      edit:false,
      loading:false,
      title:null,
      participantUserSearch:null,
      users:[],
      userLoading:false,
      userDetail:null,
      conversation:{
        user_ids:[],
        users:[],
        title:null,
        description:null,
        private:false,
        conversation_admin:null,
        admin_messageable_type:'User'
      }
    }
  },
  methods:{
    openDialog(){
      this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
    },
    createNewConversation(){
      this.title='Create';
      this.edit=false;
      this.openDialog();
    },
    createOrUpdate(){
      if (!this.edit) this._create();
      else this.update();
    },
    resetForm(){
      this.title=null;
      this.edit=false;
      this.conversation={
        users:[],
        title:null,
        description:null,
        private:false,
      }
      this.users=[];
      this.participantUserSearch=null;
      this.userDetail=null;
    },
    convertData(){
      this.conversation.user_ids=[];
      if(this.conversation.users.length > 0){
        this.conversation.users.map((user) => {
          this.conversation.user_ids.push(user.id);
        });
      }
    },
    _create: function () {
     this.loading=true;
      this.convertData();
      this.conversation.conversation_admin=this.currentUser.id;
      delete  this.conversation.users;
      chat
          .createNewConversation(this.conversation)
          .then(response => {
            this.$snotify.success("Conversation added");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {

            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => (this.loading = false))
    },
    async searchUser(data) {
      this.userLoading=true;
      user
          .searchUserForChat(data)
          .then((response) => {
            this.users = [];
            let users=[];
            users = response.data.users;
            if(users.length > 0){
              users.map((user) => {
                user.display_text = `${user.personal_id.toUpperCase()} - ${user.full_name} - ${user.email}`
                this.users.push(user);
              });
            }else{
              this.users=[];
            }

          })
          .catch(err => {

          })
          .finally(() => (this.userLoading = false))
    },
    changeUser(){
      if(this.userDetail){
        let userDetail=this.userDetail;

        let obj = null;

        if(this.conversation.users && this.conversation.users.length > 0){
          let index=this.conversation.users.findIndex(val => val.id===userDetail.id);
          obj = this.conversation.users[index];
        }

        if(obj == undefined || obj == null){
          this.conversation.users.push(this.userDetail);
        }else{
          this.$snotify.error("Already been list");
        }

        this.userDetail=null;
        this.users=[];
        this.participantUserSearch=null;
      }

    },
    removeUser(index){
      this.conversation.users.splice(index,1);
    },
  },
  watch:{
    participantUserSearch(val) {
      let data = {
        info:val,
      };
      this.searchUser(data);
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
}
</script>